<template>
    <div>
        <CSDialog
            dialogTitle="提示"
            dialogWidth="480px"
            :dialogVisible="deleteAssessmentVisible"
            dialog-header-class=" "
            dialog-confirm-btn-text="确定"
            dialog-header-icon="icon-menua-zu13"
            @onClose="deleteAssessmentVisible = false"
            @onConfirm="onOk"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; text-align: center; font-size: 24px; color: #000;"
            >
                确定删除吗？
            </div>
        </CSDialog>
    </div>
</template>


<script>
import {
    disableGeneralFormUrl,
    disableProfessionalItemUrl,
    disableGeneralItemUrl,
} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";

// 删除时的类型
const DELETE_TYPE = {
    TABLE: 1, // 删除通用考核表
    BUSINESS_ITEM: 2, // 删除业务考核项
    COMMON_ITEM: 3, // 删除通用考核项
};

export default {
    props: {
        deleteType: Number,
        deleteInfo: Object,
    },
    components: {
        CSDialog,
    },
    data() {
        return {
            deleteAssessmentVisible: false,
        };
    },
    methods: {
        onOk() {
            const methodParam = {
                [DELETE_TYPE.TABLE]: disableGeneralFormUrl,
                [DELETE_TYPE.BUSINESS_ITEM]: disableProfessionalItemUrl,
                [DELETE_TYPE.COMMON_ITEM]: disableGeneralItemUrl,
            };
            this.$fly
                .post(methodParam[this.deleteType], {
                    ...this.deleteInfo,
                    operatorId: this.$vc.getCurrentStaffInfo().id,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.changeShowStatus(false);
                    this.$vc.emit(this.$route.path, "assessmentManage", "list", null);
                    this.$vc.toast("删除成功");
                });
        },
        changeShowStatus(isShow) {
            if (isShow) {
                this.deleteAssessmentVisible = true;
            } else {
                this.deleteAssessmentVisible = false;
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.modal
    font-size 24px
    &-title
        font-size 24px
    &-footer
        text-align center
        font-size 24px
        button
            font-size 24px
            &:not(:last-of-type)
                margin-right 30px
</style>
